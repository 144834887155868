export const colorTokens = {
    grey: {
      0: "#FFFFFF",
      10: "#F6F6F6",
      50: "#F0F0F0",
      100: "#E0E0E0",
      200: "#C2C2C2",
      300: "#A3A3A3",
      400: "#858585",
      500: "#666666",
      600: "#4D4D4D",
      700: "#383f57",
      800: "#383f57",
      900: "#212021",
      1000: "#000000",
    },
    primary: {
      50: "#a4c0f8",
      100: "#7ca7f7",
      200: "#649af7",
      300: "#488bf7",
      400: "#277af7",
      500: "#5d54ad",
      600: "#0255d1",
      700: "#433a94",
      800: "#043995",
      900: "#05307e",
    },
  };
  
export const themeSettings = () => {
    return {
      palette: {
        primary: {
          dark: colorTokens.primary[700],
          main: colorTokens.primary[500],
          light: colorTokens.primary[50],
        },
        neutral: {
          dark: colorTokens.grey[700],
          main: colorTokens.grey[500],
          mediumMain: colorTokens.grey[400],
          medium: colorTokens.grey[200],
          light: colorTokens.grey[700],
        },
        background: {
          default: "#fff",
          alt: colorTokens.grey[800],
        },
        text: {
          primary: '#6e65b8' , // Setting text color based on mode
          secondary: colorTokens.grey[500],
          fontFamily: '"Comic Sans MS", "Comic Sans", cursive',
        }
      },
      typography: {
        fontFamily: [
          'Montserrat',
          '-apple-system',
          'BlinkMacSystemFont',
          '"Segoe UI"',
          'Roboto',
          '"Helvetica Neue"',
          'Arial',
          'sans-serif',
          '"Apple Color Emoji"',
          '"Segoe UI Emoji"',
          '"Segoe UI Symbol"',
        ].join(','),
        fontSize: 14,
        h1: {
          fontSize: 44,
        },
        h2: {
          fontSize: 36,
        },
        h3: {
          fontSize: 28,
        },
        h4: {
          fontSize: 24,
        },
        h5: {
          fontSize: 20,
        },
        h6: {
          fontSize: 18,
        },
        h7: {
          fontSize: 16,
        },
      },
    };
  };