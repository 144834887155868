import React, { useState, useEffect, useRef } from 'react';
import { Box, ImageList, Stack, Typography, useMediaQuery, TextField, Button, Dialog, DialogTitle, DialogContent, DialogActions } from '@mui/material';
import CopyText from './common/CopyText';
import cats from './items/cats.json';
import fbiVideo from './images/fbi.mp4';

function Home() {
    const mobile = useMediaQuery("(max-width: 800px)");
    const tablet = useMediaQuery("(max-width: 1000px)");
    const laptop = useMediaQuery("(max-width: 1160px)");
    const desktop = useMediaQuery("(min-width: 1400px)");
    const columns = mobile ? 1 : tablet ? 2 : laptop ? 3 : 4;
    const [text, setText] = useState('');
    const [decryptedText, setDecryptedText] = useState('');
    const [encryptedData, setEncryptedData] = useState([]);
    const [data, setData] = useState(cats);
    const [welcomeOpen, setWelcomeOpen] = useState(true);
    const [showImage, setShowImage] = useState(false);
    const videoRef = useRef(null);

    const handleWelcomeClose = () => {
        setWelcomeOpen(false);
    };

    const shiftLetter = (char, shift) => {
        const code = char.charCodeAt(0);
        if (code >= 65 && code <= 90) { // Uppercase letters
            return String.fromCharCode(((code - 65 + shift + 26) % 26) + 65);
        }
        if (code >= 97 && code <= 122) { // Lowercase letters
            return String.fromCharCode(((code - 97 + shift + 26) % 26) + 97);
        }
        return char; // Non-alphabetic characters are returned as-is
    };

    const caesarCipher = (text, shift) => {
        return text.split('').map(char => shiftLetter(char, shift)).join('');
    };

    const encryptText = (plainText) => {
        return caesarCipher(plainText, 3); // Shift letters by 3 positions to the right
    };

    const decryptText = (cipherText) => {
        return caesarCipher(cipherText, -3); // Shift letters by 3 positions to the left
    };

    const handleDecrypt = () => {
        try {
            console.log(text);
            const decrypted = decryptText(text);
            console.log(decrypted);
            setDecryptedText(decrypted);
            setText('');
        } catch (error) {
            console.error(error);
        }
    };

    const handleBoxClick = () => {
        const videoElement = videoRef.current;
        if (videoElement) {
            videoElement.style.display = 'block'; // Ensure video is visible
            const playPromise = videoElement.play();

            if (playPromise !== undefined) {
                playPromise.then(() => {
                    if (videoElement.requestFullscreen) {
                        videoElement.requestFullscreen();
                    } else if (videoElement.webkitEnterFullscreen) { // iOS devices
                        videoElement.webkitEnterFullscreen();
                    } else if (videoElement.msRequestFullscreen) {
                        videoElement.msRequestFullscreen();
                    }
                }).catch(error => {
                    console.error('Error attempting to play the video:', error);
                });
            }
        }
    };

    const handleVideoEnd = () => {
        setShowImage(true);
    };

    useEffect(() => {
        const encrypted = data.map(item => ({
            ...item,
            encryptedName: encryptText(item.name),
        }));
        setEncryptedData(encrypted);
    }, [data]); // Use data as dependency

    if (showImage) {
        return (
            <Box
                component="img"
                src="https://assets.bwbx.io/images/users/iqjWHBFdfxIU/iaNd_Qx2bA3o/v1/-1x-1.jpg"
                alt="Replacement Image"
                sx={{ width: '100vw', height: '100vh' }}
            />
        );
    }

    return (
        <Stack spacing={1} p={0}>
            <Stack direction="row" px={'1vw'}>
                <Stack boxShadow={4} p={2} width='14vw' minWidth={mobile ? '140px' : '200px'}>
                    <Typography fontWeight={500} fontSize={mobile ? 12 : (desktop ? 20 : 16)}>Memes <span style={{ color: 'gray', fontSize: mobile ? 11 : (desktop ? 17 : 14), cursor: 'default' }}><i>42,069</i></span></Typography>
                    <Typography fontWeight={500} sx={{ml: 2}} fontSize={mobile ? 12 : (desktop ? 20 : 16)}>Cats <span style={{ color: 'gray', fontSize: mobile ? 11 : (desktop ? 17 : 14), cursor: 'default' }}><i>983</i></span></Typography>
                    <Typography fontWeight={500} sx={{ml: 2}} fontSize={mobile ? 12 : (desktop ? 20 : 16)}>Dogs <span style={{ color: 'gray', fontSize: mobile ? 11 : (desktop ? 17 : 14), cursor: 'default' }}><i>726</i></span></Typography>
                    <Typography fontWeight={500} sx={{ml: 2}} fontSize={mobile ? 12 : (desktop ? 20 : 16)}>Celebrities <span style={{ color: 'gray', fontSize: mobile ? 11 : (desktop ? 17 : 14), cursor: 'default' }}><i>1,083</i></span></Typography>
                    <Typography fontWeight={500} sx={{ml: 2}} fontSize={mobile ? 12 : (desktop ? 20 : 16)}>Book <span style={{ color: 'gray', fontSize: mobile ? 11 : (desktop ? 17 : 14), cursor: 'default' }}><i>480</i></span></Typography>
                    <Typography fontWeight={500} sx={{ml: 2}} fontSize={mobile ? 12 : (desktop ? 20 : 16)}>Politician <span style={{ color: 'gray', fontSize: mobile ? 11 : (desktop ? 17 : 14), cursor: 'default' }}><i>290</i></span></Typography>
                    <Typography fontWeight={500} sx={{ml: 2}} fontSize={mobile ? 12 : (desktop ? 20 : 16)}>Wif <span style={{ color: 'gray', fontSize: mobile ? 11 : (desktop ? 17 : 14), cursor: 'default' }}><i>312</i></span></Typography>
                    <Typography fontWeight={500} fontSize={mobile ? 12 : (desktop ? 20 : 16)}>Twitter Accounts <span style={{ color: 'gray', fontSize: mobile ? 11 : (desktop ? 17 : 14), cursor: 'default' }}><i>5,237</i></span></Typography>
                    <Typography fontWeight={500} fontSize={mobile ? 12 : (desktop ? 20 : 16)}>AI Deepfakes <span style={{ color: 'gray', fontSize: mobile ? 11 : (desktop ? 17 : 14), cursor: 'default' }}><i>367</i></span></Typography>
                    <Typography fontWeight={500} fontSize={mobile ? 12 : (desktop ? 20 : 16)}>NFTs <span style={{ color: 'gray', fontSize: mobile ? 11 : (desktop ? 17 : 14), cursor: 'default' }}><i>10,512</i></span></Typography>
                    <Typography fontWeight={500} fontSize={mobile ? 12 : (desktop ? 20 : 16)}>MEV Bots <span style={{ color: 'gray', fontSize: mobile ? 11 : (desktop ? 17 : 14), cursor: 'default' }}><i>523</i></span></Typography>
                    <Stack sx={{ border: '1px solid gray', mt: 2, p: 1, }} spacing={1}>
                        <Typography fontWeight={900} sx={{ textAlign: 'center' }} fontSize={mobile ? 14 : (desktop ? 20 : 16)}>Decryption Tool</Typography>
                        <TextField placeholder='Encrypted Item' size="small" value={text} onChange={(e) => setText(e.target.value)} />
                        <Button variant="contained" sx={{ color: 'white' }} fullWidth={true} onClick={handleDecrypt} size={mobile ? "small" : (desktop ? "large" : "medium")}>Decrypt</Button>
                        {decryptedText && <Typography sx={{ textAlign: 'center', color: 'green' }} fontSize={mobile ? 16 : 18}>{decryptedText}</Typography>}
                    </Stack>
                </Stack>
                <Stack boxShadow={2} p={2} width='84vw'>
                    <Box width='100%' display='flex' justifyContent='center' sx={{ flexGrow: 1, height: mobile ? '64vh' : 'auto'}} overflowY='auto'>
                        <ImageList cols={columns} rowHeight={300} gap={20}>
                            {encryptedData.map((item, index) => (
                                <Stack key={index} p={1} spacing={0.5} alignItems="center">
                                    <Box width={mobile ? '40vw' : '18vw'}
                                        sx={{ position: "relative", cursor: 'pointer', ':hover': { boxShadow: '0 0 8px 1px lightgray' } }}
                                        onClick={handleBoxClick}
                                    >
                                        <img
                                            src={item.image_url}
                                            alt={item.name}
                                            loading="lazy"
                                            height='auto'
                                            width={'100%'}
                                        />
                                    </Box>
                                    {mobile ? <Typography color='gray' fontSize={12}>---BEGIN PGP SIG---</Typography> : <Typography color='gray' fontSize={16}>----BEGIN PGP SIGNATURE----</Typography>}
                                    <Box onClick={()=>setText(item.encryptedName)}><CopyText text={item.encryptedName}  /> </Box>
                                    {mobile ? <Typography color='gray' fontSize={12}>---END PGP SIG---</Typography> : <Typography color='gray' fontSize={16}>---END PGP SIGNATURE---</Typography>}
                                    <Typography color='green' fontSize={mobile ? 12 : (desktop ? 20 : 16)}>{item.price} SOL</Typography>
                                </Stack>
                            ))}
                        </ImageList>
                    </Box>
                </Stack>
            </Stack>
            <Dialog open={welcomeOpen} onClose={handleWelcomeClose}>
                <DialogTitle fontSize={desktop ? 24 : 16}>🎂 Birthday Sale</DialogTitle>
                <DialogContent>
                    <Typography fontSize={desktop ? 20 : 16}>This is Dread Pirate Roberts.</Typography>
                    <Typography fontSize={desktop ? 20 : 16}>Today's my birthday, so everything will be 50% off.</Typography>
                    <br />
                    <Typography fontSize={desktop ? 20 : 16}>Enjoy!</Typography>
                    <br />
                    <img src="https://cdn.costumewall.com/wp-content/uploads/2019/07/dread-pirate-roberts.jpg" alt="Dread Pirate Roberts" width={desktop ? "300px" : "220px" } height="auto" />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleWelcomeClose}>Close</Button>
                </DialogActions>
            </Dialog>
            <video ref={videoRef} onEnded={handleVideoEnd} style={{ display: 'none' }}>
                <source src={fbiVideo} type="video/mp4" />
            </video>
        </Stack>
    );
}

export default Home;
