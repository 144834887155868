import React from 'react';
import {  Button, Stack, useMediaQuery, Dialog, DialogTitle, DialogContent, DialogActions,
    ListItemText, ListItemButton, IconButton, Box, Typography} from '@mui/material';
import { useState, useEffect } from 'react';
import { Link as ReactRouterLink } from 'react-router-dom';
import Socials from './Socials';
import logo from '../images/sol_road.webp'

const Navbar = React.memo(({mode}) => { 
    const desktop = useMediaQuery("(min-width: 1400px)");
    const tablet = useMediaQuery("(max-width: 920px)");
    const mobile = useMediaQuery("(max-width: 600px)");
    const [welcomeOpen, setWelcomeOpen] = useState(false);
    const [clicked, setClicked] = useState(false);
    const [tokenomicsOpen, setTokenomicsOpen] = useState(false);
    const [roadmapOpen, setRoadmapOpen] = useState(false);

    const handleTokenomicsOpen = () => {
        setTokenomicsOpen(true);
    }

    const handleRoadmapOpen = () => {
        setRoadmapOpen(true);
    }

    const handleTokenomicsClose = () => {
        setTokenomicsOpen(false);
    }

    const handleRoadmapOpenClose = () => {
        setRoadmapOpen(false);
    }

    const handleWelcomeOpen = () => {
        if (!clicked)
            setWelcomeOpen(true);
    }

    const handleWelcomeClose = () => {
        setWelcomeOpen(false);
        setClicked(true);
    }

      return (
        <Stack direction="row" alignItems="center" px={2}>
            {(tablet || mobile) ? 
             <> <Box sx={{flexGrow: 1}}/>
             <Stack spacing={.5} alignItems="center" display="flex">
                <Stack direction="row" alignItems="center" sx={{":hover": {cursor: 'pointer'}}} onClick={()=>window.location.reload()}>
                    <Box  mt={2} component={ReactRouterLink} to="" ><img src={logo} alt="logo" width={mobile ? "100px" : "160px"} height="auto"/></Box>
                    <Stack>
                        <Typography fontSize={mobile ? 30 : 40} align="center" fontWeight={700} color='#424242' height={mobile ? '40px' : '50px'}>Sol Road</Typography>
                        <Typography fontSize={mobile ? 14 : 18} align="center" fontWeight="bold" color="#969696"><i>degenerate market</i></Typography>
                    </Stack>
                </Stack>
                <Typography fontSize={mobile ? 12 : 16} align="center" >messages <span style={{fontWeight: 'bold', cursor: "pointer"}} onClick={handleWelcomeOpen}>{clicked ? 0 : 1}</span> <span style={{color: 'darkgray'}}>&nbsp;|&nbsp;</span>orders <b>0</b><span style={{color: 'darkgray'}}>&nbsp;|&nbsp;</span>account <b>50 SOL</b></Typography>
                <Box sx={{flexGrow: 1}}/>
                <Stack direction="row" spacing={2} alignItems="center">
                    <Typography fontSize={12} align="center" ><span onClick={handleTokenomicsOpen} style={{cursor: 'pointer'}}>Tokenomics</span> <span style={{color: 'darkgray'}}>&nbsp;|&nbsp;</span> <span onClick={handleRoadmapOpen} style={{cursor: 'pointer'}}>Roadmap</span></Typography>
                    <Socials size1={28} size2={26} size3={26} />
                </Stack>
                <Box height='2vh' />
             </Stack>
             <Box sx={{flexGrow: 1}}/>
             </>
            :
            <>
            <Box sx={{":hover": {cursor: 'pointer'}}} mt={2} onClick={()=>window.location.reload()} ><img src={logo} alt="logo" width={desktop ? '240px' : "160px"} height="auto"/></Box>
            <Stack sx={{":hover": {cursor: 'pointer'}}} onClick={()=>window.location.reload()}>
                <Typography fontSize={desktop ? 50 : 40} align="center" fontWeight={700} color='#424242' height={desktop ? '62px' : '50px'}>Sol Road</Typography>
                <Typography fontSize={desktop ? 25 : 18} align="center" fontWeight="bold" color="#969696"><i>degenerate market</i></Typography>
            </Stack>
            <Box sx={{flexGrow: 1}}/>
            <Typography fontSize={desktop ? 20 : 16} align="center" >messages <span onClick={()=>setWelcomeOpen(true)} style={{fontWeight: 'bold', cursor: "pointer"}}>1</span> <span style={{color: 'darkgray'}}>&nbsp;|&nbsp;</span>orders <b>0</b><span style={{color: 'darkgray'}}>&nbsp;|&nbsp;</span>account <b>50 SOL</b></Typography>
            <Box sx={{flexGrow: 1}}/>
            <Typography fontSize={desktop ? 20 : 16} align="center" ><span onClick={handleTokenomicsOpen} style={{cursor: 'pointer'}}>Tokenomics</span> <span style={{color: 'darkgray'}}>&nbsp;|&nbsp;</span> <span onClick={handleRoadmapOpen} style={{cursor: 'pointer'}}>Roadmap</span></Typography>
            <Box sx={{flexGrow: 1}}/>
            <Socials size1={desktop ? 44 : 34} size2={desktop ? 40 : 30} size3={desktop ? 40 : 30} />
            <Box sx={{flexGrow: 1}}/>
            </>
            }
            <Dialog open={welcomeOpen} onClose={handleWelcomeClose}>
                <DialogTitle fontSize={desktop ? 24 : 16}>🎂 Birthday Sale</DialogTitle>
                <DialogContent>
                    <Typography fontSize={desktop ? 20 : 16}>This is Dread Pirate Roberts.</Typography>
                    <Typography fontSize={desktop ? 20 : 16}>Today's my birthday, so everything will be 50% off.</Typography>
                    <br />
                    <Typography fontSize={desktop ? 20 : 16}>Enjoy!</Typography>
                    <br />
                    <img src="https://cdn.costumewall.com/wp-content/uploads/2019/07/dread-pirate-roberts.jpg" alt="Dread Pirate Roberts" width={desktop ? "300px" : "220px" } height="auto" />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleWelcomeClose}>Close</Button>
                </DialogActions>
            </Dialog>
            <Dialog open={tokenomicsOpen} onClose={handleTokenomicsClose}>
                <DialogTitle fontSize={ mobile ? '18px' : '1.5vw' }>Tokenomics</DialogTitle>
                <DialogContent>
                    <Typography fontSize={ mobile ? '16px' : '1.5vw' }>LP Burnt</Typography>
                    <Typography fontSize={ mobile ? '16px' : '1.5vw' }>Mint Revoked</Typography>
                    <br></br>
                    <Typography fontSize={ mobile ? '16px' : '1.5vw' }>90% Fair Launch</Typography>
                    <Typography fontSize={ mobile ? '16px' : '1.5vw' }>9% Team</Typography>
                    <Typography fontSize={ mobile ? '16px' : '1.5vw' }>1% Marketing</Typography>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleTokenomicsClose}>Close</Button>
                </DialogActions>
            </Dialog>
            <Dialog open={roadmapOpen} onClose={handleRoadmapOpenClose}>
                <DialogTitle fontSize={ mobile ? '18px' : '1.5vw' }>Roadmap</DialogTitle>
                <DialogContent>
                    <Typography fontSize={ mobile ? '16px' : '1.5vw' }>1. Fair Launch on Pump.fun</Typography>
                    <Typography fontSize={ mobile ? '16px' : '1.5vw' }>2. Migrate to Raydium</Typography>
                    <Typography fontSize={ mobile ? '16px' : '1.5vw' }>3. Build Live Sol Road Ecosystem (Product listings, comment threads, etc.)</Typography>
                    <Typography fontSize={ mobile ? '16px' : '1.5vw' }>4. List on CEXs</Typography>
                    <Typography fontSize={ mobile ? '16px' : '1.5vw' }>5. Moon!</Typography>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleRoadmapOpenClose}>Close</Button>
                </DialogActions>
            </Dialog>
        </Stack>
    );
});
export default Navbar;