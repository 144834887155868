import React, { useState } from 'react';
import { Typography, IconButton, Snackbar, useMediaQuery } from '@mui/material';
import { ContentCopy as ContentCopyIcon } from '@mui/icons-material';
import { styled } from '@mui/material/styles';

const Container = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  position: 'relative',
}));

const TextWrapper = styled('div')(({ theme }) => ({
  flex: 1,
  textAlign: 'center',
}));

const StyledIconButton = styled(IconButton)(({ theme }) => ({
  position: 'absolute',
  right: '-32px',
}));

const CopyToClipboardComponent = ({ text }) => {
    const mobile = useMediaQuery("(max-width: 800px)");
  const [open, setOpen] = useState(false);

  const handleCopyClick = () => {
    try {
      // Attempt to use the clipboard API
      navigator.clipboard.writeText(text).then(
        () => {
          setOpen(true);
        },
        (err) => {
          console.error('Async clipboard copy failed: ', err);
          // Fallback method
          fallbackCopyTextToClipboard(text);
        }
      );
    } catch (err) {
      console.error('Clipboard API not available: ', err);
      // Fallback method
      fallbackCopyTextToClipboard(text);
    }
  };

  const fallbackCopyTextToClipboard = (text) => {
    const textArea = document.createElement('textarea');
    textArea.value = text;
    textArea.style.position = 'fixed';  // Avoid scrolling to bottom
    textArea.style.opacity = 0; // Hide the textarea
    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();

    try {
      const successful = document.execCommand('copy');
      if (successful) {
        setOpen(true);
      } else {
        console.error('Fallback copy command was unsuccessful');
      }
    } catch (err) {
      console.error('Fallback: Oops, unable to copy', err);
    }

    document.body.removeChild(textArea);
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  return (
    <Container>
      <TextWrapper>
        <Typography variant="body1">{text}</Typography>
      </TextWrapper>
      <StyledIconButton onClick={handleCopyClick} aria-label="copy">
        <ContentCopyIcon sx={{ fontSize: '14px' }} />
      </StyledIconButton>
      {!mobile && <Snackbar
        open={open}
        autoHideDuration={3000}
        onClose={handleClose}
        sx={{color: 'white'}}
        message="Copied to clipboard"
      /> }
    </Container>
  );
};

export default CopyToClipboardComponent;
